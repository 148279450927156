import React, {FC, useEffect, useReducer} from "react";
import { TreatmentContextType, TreatmentResultType, TreatmentType } from "../types/TreatmentType";
import { TreatmentReducer } from "../reducers/TreatmentReducer";
import { TreatmentModel } from "../models/TreatmentModel";
import { ExamContextType } from "../types/ExamType";
import { ExamModel } from "../models/ExamModel";
import { ExamReducer } from "../reducers/ExamReducer";

interface Props {
    medical_record_id: number
}

const DefaultData: ExamContextType = {
    exams: [],
    exam_options: [],
    loading: false,
    loadExams: (medical_record_id: number) => {}   
};
const ExamContext = React.createContext<ExamContextType>(DefaultData);
const ExamContextProvider: FC <Props> = ({children, medical_record_id}) => {    

    const loadExams = (medical_record_id: number) => {
        dispatch({type: 'setLoading', loading: true});
        ExamModel().list(medical_record_id).then((resp) => {
            dispatch({type: 'setLoading', loading: false});
            if(typeof resp !== "boolean") {
                dispatch({type: 'setExams', exams: resp.exams});
                dispatch({type: 'setExamOptions', exam_options: resp.exam_options});
            }
        });
    }

    useEffect(() => {
        loadExams(medical_record_id);
    }, []);

    const [data, dispatch] = useReducer(ExamReducer, {
        ...DefaultData,
        loadExams: loadExams
    });
    return (<ExamContext.Provider value={data}>{children}</ExamContext.Provider>);
}

export {ExamContextProvider, ExamContext}