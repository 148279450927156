import React, {FC, useEffect, useState} from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { useConfig } from '../hooks/useConfig';
import { PersonModel } from '../models/PersonModel';
import { PairingModel } from '../models/PairingModel';
import { FormattedMessage } from 'react-intl';
import { LANG_COMMON_LOADING } from '../lang';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

interface Props {
    coordinates: Array<any>
}

const Map: FC<Props> = ({coordinates}) => {
  const [pointList, setPointList] = useState<Array<any>>(coordinates);
  const {getConfig} = useConfig();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: getConfig("GOOGLE_APIKEY"),
    language: 'pt_BR'
  });

  useEffect(() => {
    setPointList(coordinates);
  }, [coordinates]);

  useEffect(() => {}, [pointList])

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    if(pointList.length > 0) {
      const bounds = new window.google.maps.LatLngBounds({lat: parseFloat(pointList[0].lat), lng: parseFloat(pointList[0].lng)});
      pointList.map((point) => {
        if(point.lat !== null && point.lng !== null)
          bounds.extend({lat: parseFloat(point.lat), lng: parseFloat(point.lng)});
      });

      map.fitBounds(bounds);
    }
    setMap(map);
  }, []);

  const toggleInfoWindow = (index: any) => {
    pointList[index].isOpen = (pointList[index].isOpen === true) ? false : true;
    pointList[index].title = <FormattedMessage {...LANG_COMMON_LOADING} />;
    setPointList([...pointList]);
    
    if(pointList[index].isPerson) {
      PersonModel().get(pointList[index].id).then((person) => {
        if(typeof person !== 'boolean') {
          pointList[index].title = `${person.name}`;
          setPointList([...pointList]);
        }
      });
    } else {
      PairingModel().get(pointList[index].id).then((pairing) => {
        if(typeof pairing !== 'boolean') {
          pointList[index].title = `${pairing?.id} - ${pairing?.donor_person?.name} / ${pairing?.receiver_person?.name}`;
          setPointList([...pointList]);
        }
      });
    }
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, [])

  const pinSymbol = (color: string) => {
    return {
        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: '#000',
        strokeWeight: 2,
        scale: 1,
   };
}

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <>
            {pointList.map((point, index) => {
                if(point.lat !== null && point.lng !== null) {
                  return (
                    <Marker 
                      position={{lat: parseFloat(point.lat), lng: parseFloat(point.lng)}}
                      icon={pinSymbol(point.color)}
                      key={`${index}`}
                      onClick={() => {
                        toggleInfoWindow(index);
                      }}
                      >
                        {point.isOpen && (<InfoWindow onCloseClick={() => toggleInfoWindow(index)}><span>{point.title}</span></InfoWindow>)}
                    </Marker>);

                }


                    
            })}
        </>
      </GoogleMap>
  ) : <></>
}

export default Map;