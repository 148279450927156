import React, {FC, useEffect, useReducer} from "react";
import { MedicalRecordContextType, MedicalRecordType } from "../types/MedicalRecordType";
import { MedicalRecordModel } from "../models/MedicalRecordModel";
import { MedicalRecordReducer } from "../reducers/MedicalRecordReducer";

interface Props {
    couple_id: number | string
}

const DefaultData: MedicalRecordContextType = {
    medical_record: undefined,
    setMedicalRecord: (medical_record) => {},
    loading: false,
    reload: () => {}
};
const CoupleMedicalRecordContext = React.createContext<MedicalRecordContextType>(DefaultData);
const CoupleMedicalRecordContextProvider: FC <Props> = ({children, couple_id}) => {    

    const setMedicalRecord = (medical_record: MedicalRecordType) => {
        dispatch({type: 'setMedicalRecord', medical_record: medical_record});
    }

    const loadMedicalRecord = (couple_id: number | string) => {
        dispatch({type: 'setLoading', loading: true});
        MedicalRecordModel().get(couple_id).then((medical_record) => {
            dispatch({type: 'setLoading', loading: false});
            if(typeof medical_record !== "boolean") {
                setMedicalRecord(medical_record);                
            }
        });
    }

    const reload = () => {
        loadMedicalRecord(couple_id);
    }

    useEffect(() => {
        loadMedicalRecord(couple_id);
    }, [])

    const [data, dispatch] = useReducer(MedicalRecordReducer, {
        ...DefaultData,
        setMedicalRecord: setMedicalRecord,
        reload: reload
    });
    return (<CoupleMedicalRecordContext.Provider value={data}>{children}</CoupleMedicalRecordContext.Provider>);
}

export {CoupleMedicalRecordContextProvider, CoupleMedicalRecordContext}