import React, { FC, useState } from "react";
import { Form, Modal, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import { CoupleType, PersonType } from "../../../../types/PersonType";
import { PersonModel } from "../../../../models/PersonModel";
import { isResponseError, ResponseErrorType } from "../../../../types/CommonType";
import TranslateTerms from "../../../../components/TranslateTerms";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_CANCEL, LANG_COMMON_DIAGNOSIS, LANG_COMMON_EVOLUTION, LANG_COMMON_LEGAL_ID, LANG_COMMON_LOADING, LANG_COMMON_MEDICAL_RECORD_NUMBER, LANG_COMMON_NAME, LANG_COMMON_NEW, LANG_COMMON_PATIENT, LANG_COMMON_SAVE, LANG_ERROR, LANG_WARNING_FILL_ALL } from "../../../../lang";
import InputMask from 'react-input-mask';
import clsx from "clsx";
import { CoupleModel } from "../../../../models/CoupleModel";
import { MedicalRecordEvolutionType, MedicalRecordType } from "../../../../types/MedicalRecordType";
import { MedicalRecordModel } from "../../../../models/MedicalRecordModel";
import { DefaultUser } from "../../../../providers/DefaultData";
import { BtnBold, BtnBulletList, BtnItalic, BtnNumberedList, BtnStrikeThrough, BtnUnderline, BtnUndo, Editor, EditorProvider, Separator, Toolbar } from "react-simple-wysiwyg";

interface Props {
    show: boolean
    onHide: () => void
    onSave: (medical_record_evolution: MedicalRecordEvolutionType) => void
    medical_record: MedicalRecordType
    type: string
}

const MedicalRecordEvolutionAddModal: FC<Props> = ({ show, onHide, onSave, medical_record, type }) => {
    const schema = yup.object().shape({
        content: yup.string().required(),
        diagnosis: yup.string().nullable()
    });
    const [formWasSubmitted, setFormWasSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const intl = useIntl();
    const DefaultErrorMsg = intl.formatMessage(LANG_ERROR);
    const [errorMsg, setErrorMsg] = useState(DefaultErrorMsg);

    const initialPerson: MedicalRecordEvolutionType = {
        id: -1,
        content: "",
        type: type,
        created_at: "",
        user: DefaultUser
    };

    const onSubmit = async (values: MedicalRecordEvolutionType) => {
        setLoading(true);
        setFormWasSubmitted(false);
        setErrorMsg(DefaultErrorMsg);
        setHasError(false);
        // Create person
        let resp: MedicalRecordEvolutionType | ResponseErrorType = await MedicalRecordModel().createEvolution(medical_record.id, values);
        setLoading(false);
        // Saving result
        if (!isResponseError(resp)) {
            onSave(resp as MedicalRecordEvolutionType);
        } else {
            setHasError(true);
            setErrorMsg((resp as ResponseErrorType).message);
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                setFormWasSubmitted(false);
            }}
            className="modal-default"
            size="lg"
        >
            <Formik
                validationSchema={schema}
                onSubmit={onSubmit}
                initialValues={initialPerson}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    setFieldValue,
                    isValid,
                    errors }) => (
                    <Form noValidate onSubmit={(e) => {
                        setFormWasSubmitted(true);
                        handleSubmit(e);
                    }}>
                        <Modal.Header closeButton closeVariant="white">
                            <Modal.Title>
                                <FormattedMessage {...LANG_COMMON_EVOLUTION} />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(!isValid && formWasSubmitted) && (<Alert variant={'danger'}><FormattedMessage {...LANG_WARNING_FILL_ALL} /></Alert>)}
                            {hasError && (<Alert variant={'danger'}>{errorMsg}</Alert>)}
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label><FormattedMessage {...LANG_COMMON_EVOLUTION} /></Form.Label>
                                <EditorProvider>
                                    <Editor
                                        name="content"
                                        placeholder={intl.formatMessage(LANG_COMMON_EVOLUTION)}
                                        value={values.content}
                                        onChange={(e) => {
                                            console.log(e);
                                            setFieldValue('content', e.target.value);
                                        }}
                                    >
                                        <Toolbar>
                                            <BtnBold />
                                            <BtnItalic />
                                            <BtnUnderline />
                                            <BtnStrikeThrough />
                                            <Separator />
                                            <BtnNumberedList />
                                            <BtnBulletList />
                                        </Toolbar>
                                    </Editor>
                                </EditorProvider>
                            </Form.Group>
                            {type === 'M' && (
                                <Form.Group>
                                    <Form.Label><FormattedMessage {...LANG_COMMON_DIAGNOSIS} /></Form.Label> <br />
                                    {MedicalRecordModel().getDiagnosisOptions().map((item) => (
                                        <Form.Check
                                            inline
                                            label={item.label}
                                            value={item.value}
                                            onChange={handleChange}
                                            type="radio"
                                            name="diagnosis"
                                            id={`inline-${item.value}-3`}
                                        />
                                    ))}
                                </Form.Group>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                                <FormattedMessage {...LANG_COMMON_CANCEL} />
                            </Button>
                            <Button variant="primary" type={"submit"} disabled={loading} className="btn-custom bg-custom-gradient">
                                {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> <FormattedMessage {...LANG_COMMON_LOADING} /></> : <FormattedMessage {...LANG_COMMON_SAVE} />}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default MedicalRecordEvolutionAddModal;