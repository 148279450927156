import React, { FC, useState } from "react";
import { Form, Modal, Button, Alert, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { UserModel } from "../../../../models/UserModel";
import { UserType } from "../../../../types/UserType";
import ClipLoader from "react-spinners/ClipLoader";
import { useAuth } from "../../../../hooks/useAuth";
import { AuthContext } from "../../../../providers/AuthContextProvider";
import { RodType } from "../../../../types/RodType";
import { RodModel } from "../../../../models/RodModel";
import InputMask from "react-input-mask";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_CANCEL, LANG_COMMON_CODE, LANG_COMMON_NO, LANG_COMMON_SAVE, LANG_COMMON_YES, LANG_ERROR, LANG_PERSON_NEW_ROD, LANG_PERSON_ROD_FREEZING_DATE, LANG_PERSON_ROD_FRESH, LANG_PERSON_ROD_FROZEN, LANG_PERSON_ROD_OVULES_QTY, LANG_PERSON_ROD_TYPE, LANG_PERSON_ROD_USED, LANG_WARNING_FILL_ALL } from "../../../../lang";
import PersonBasicForm from "../form/PersonBasicForm";
import { PersonType } from "../../../../types/PersonType";
import { PersonModel } from "../../../../models/PersonModel";

interface Props {
    show: boolean
    onHide: () => void
    onSave: (person: PersonType) => void
    person: PersonType
}
const PersonEditModal: FC<Props> = ({ show, onHide, onSave, person }) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState<boolean | string>(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    return (
        <Modal
            size="xl"
            show={show}
            onHide={() => {
                onHide();
            }}
            className="modal-default"
        >
            <Modal.Header closeButton closeVariant="white">
                <Modal.Title>
                    Editar Paciente
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {typeof errorMsg !== "boolean" && (<Alert variant={'danger'}>{errorMsg}</Alert>)}
                <PersonBasicForm 
                    person={person}
                    hasSubmitted={hasSubmitted}
                    onSubmit={(person) => {
                        setLoading(true);
                        setErrorMsg(false);
                        setHasSubmitted(false);
                        PersonModel().update(person.id, { ...person }).then((resp) => {
                            if (typeof resp !== "boolean") {
                                onSave(resp);
                            } else {
                                setErrorMsg("Houve um erro desconhecido! Por favor, tente novamente!");
                            }
                            setLoading(false);
                        });
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                    {intl.formatMessage(LANG_COMMON_CANCEL)}
                </Button>
                <Button 
                    onClick={() => {
                        setHasSubmitted(true);
                        setTimeout(() => {
                            setHasSubmitted(false);
                        }, 100)
                    }}
                    variant="primary" type="button" disabled={loading} className="btn-custom bg-custom-gradient">
                    {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /></> : intl.formatMessage(LANG_COMMON_SAVE)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PersonEditModal;