export function ExamReducer(state: any, action: any) {
    switch (action.type) {
        case 'setExams':
            return {...state, exams: action.exams};
        case 'setExamOptions':
            return {...state, exam_options: action.exam_options};
        case 'setLoading':
            return {...state, loading: action.loading};
        default:
            return state;
    }
}