import React, { FC, useEffect, useState } from "react";
import { Form, Modal, Button, Alert, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import { CoupleType, PersonType } from "../../../../types/PersonType";
import { PersonModel } from "../../../../models/PersonModel";
import { isResponseError, OptionType, ResponseErrorType } from "../../../../types/CommonType";
import TranslateTerms from "../../../../components/TranslateTerms";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_CANCEL, LANG_COMMON_DIAGNOSIS, LANG_COMMON_EVOLUTION, LANG_COMMON_LEGAL_ID, LANG_COMMON_LOADING, LANG_COMMON_MEDICAL_RECORD_NUMBER, LANG_COMMON_NAME, LANG_COMMON_NEW, LANG_COMMON_PATIENT, LANG_COMMON_SAVE, LANG_ERROR, LANG_WARNING_FILL_ALL } from "../../../../lang";
import InputMask from 'react-input-mask';
import clsx from "clsx";
import { CoupleModel } from "../../../../models/CoupleModel";
import { MedicalRecordEvolutionType, MedicalRecordType } from "../../../../types/MedicalRecordType";
import { MedicalRecordModel } from "../../../../models/MedicalRecordModel";
import { DefaultUser } from "../../../../providers/DefaultData";
import { BtnBold, BtnBulletList, BtnItalic, BtnNumberedList, BtnStrikeThrough, BtnUnderline, BtnUndo, Editor, EditorProvider, Separator, Toolbar } from "react-simple-wysiwyg";
import { TreatmentType } from "../../../../types/TreatmentType";
import { TreatmentModel } from "../../../../models/TreatmentModel";
import CustomSelect from "../../../../components/form/CustomSelect";
import moment from "moment";
import { ExamFile, ExamOptionType, ExamResultType, ExamType } from "../../../../types/ExamType";
import { ExamModel } from "../../../../models/ExamModel";
import { FileUploadProvider, FileUploadProviderContext } from "../../../../providers/FileUploadProvider";
import FileUploader from "../../../../components/FileUploader";
import ExamFiles from "../ExamFiles";

interface Props {
    show: boolean
    onHide: () => void
    onSave: (treatment: ExamType) => void
    medical_record: MedicalRecordType
    exam: ExamType
    exam_options: ExamOptionType[]
}

const ExamModal: FC<Props> = ({ show, onHide, onSave, medical_record, exam, exam_options }) => {
    const schema = yup.object().shape({
        type: yup.string().required(),
        date: yup.string().required()
    });
    const [formWasSubmitted, setFormWasSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const intl = useIntl();
    const DefaultErrorMsg = intl.formatMessage(LANG_ERROR);
    const [errorMsg, setErrorMsg] = useState(DefaultErrorMsg);
    const [results, setResults] = useState((exam) ? exam.results : []);
    const [type, setType] = useState(exam.type);
    const [files, setFiles] = useState<ExamFile[]>(exam.files);

    const initialExam: ExamType = (exam) ? exam
        : {
            id: -1,
            type: "",
            medical_record_id: medical_record.id,
            lab_name: "",
            date: "",
            results: [],
            files: []
        };

    const onSubmit = async (values: ExamType) => {
        setLoading(true);
        setFormWasSubmitted(false);
        setErrorMsg(DefaultErrorMsg);
        setHasError(false);

        // Create or update
        let resp: ExamType | ResponseErrorType = (!exam) ?
            await ExamModel().create({
                ...values,
                results: results
            }, medical_record.id)
            : await ExamModel().update(exam.id, {
                ...values,
                results: results
            });
        setLoading(false);
        // Saving result
        if (!isResponseError(resp)) {
            onSave(resp as ExamType);
        } else {
            setHasError(true);
            setErrorMsg((resp as ResponseErrorType).message);
        }
    }

    const loadFiles = () => {
        setLoading(true);
        if (exam) {
            ExamModel().get(exam.medical_record_id, exam.id).then((resp) => {
                if (typeof resp !== "boolean") {
                    setFiles(resp.files);
                }
                setLoading(false);
            });
        }

    }

    useEffect(() => { }, [type]);
    useEffect(() => { }, [exam]);

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                setFormWasSubmitted(false);
            }}
            className="modal-default"
            size="lg"
        >
            <Formik
                validationSchema={schema}
                onSubmit={onSubmit}
                initialValues={initialExam}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    setFieldValue,
                    isValid,
                    errors }) => (
                    <Form noValidate onSubmit={(e) => {
                        setFormWasSubmitted(true);
                        handleSubmit(e);
                    }}>
                        <Modal.Header closeButton closeVariant="white">
                            <Modal.Title>
                                Exames
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(!isValid && formWasSubmitted) && (<Alert variant={'danger'}><FormattedMessage {...LANG_WARNING_FILL_ALL} /></Alert>)}
                            {hasError && (<Alert variant={'danger'}>{errorMsg}</Alert>)}
                            <Row>
                                <Col lg={2}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Data</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="date"
                                            value={values.date}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Tipo</Form.Label>
                                        <CustomSelect
                                            options={ExamModel().getExamTypes()}
                                            name="type"
                                            onChange={(o: OptionType) => {
                                                setFieldValue('type', o.value);
                                                setType(o.value);
                                            }}
                                            placeholder="Selecione..."
                                            value={values.type}
                                            isClearable={false}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Laboratório</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lab_name"
                                            placeholder="Laboratório"
                                            value={values.lab_name}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {type && (
                                    <Col lg={12}>
                                        <div className="title-divider">
                                            <h6>EXAMES</h6>
                                            <hr />
                                        </div>
                                    </Col>
                                )}
                                {exam_options.map((item, index) => {
                                    if (item.type === type && item.format !== "LONGTEXT") {
                                        return (
                                            <Col lg={3} className="exam-list">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{item.label}</Form.Label>
                                                    {item.format === 'TEXT' && (
                                                        <Form.Control
                                                            type="text"
                                                            name={`exam-${item.code}`}
                                                            value={ExamModel().getResult(item.code, results)?.value}
                                                            onChange={(e) => {
                                                                let result: ExamResultType | boolean = false;
                                                                for (let i in results) {
                                                                    if (results[i].code === item.code) {
                                                                        results[i].value = e.target.value;
                                                                        result = results[i];
                                                                    }
                                                                    setResults([...results]);
                                                                }

                                                                if (typeof result === "boolean") {
                                                                    setResults([...results, {
                                                                        code: item.code,
                                                                        value: e.target.value,
                                                                        id: -1,
                                                                        exam_id: exam?.id
                                                                    }])
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                    {item.format === 'OPTION' && (
                                                        <CustomSelect
                                                            options={ExamModel().getExamOptions()}
                                                            name={`exam-${item.code}`}
                                                            onChange={(o: OptionType) => {
                                                                let result: ExamResultType | boolean = false;
                                                                for (let i in results) {
                                                                    if (results[i].code === item.code) {
                                                                        results[i].value = o.value;
                                                                        result = results[i];
                                                                    }
                                                                    setResults([...results]);
                                                                }

                                                                if (typeof result === "boolean") {
                                                                    setResults([...results, {
                                                                        code: item.code,
                                                                        value: o.value,
                                                                        id: -1,
                                                                        exam_id: exam?.id
                                                                    }])
                                                                }

                                                            }}
                                                            placeholder="Selecione..."
                                                            value={ExamModel().getResult(item.code, results)?.value}
                                                            isClearable={false}
                                                        />
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        );
                                    }
                                })}
                                {exam_options.map((item, index) => {
                                    if (item.type === type && item.format === "LONGTEXT") {
                                        return (
                                            <Col lg={12} className="exam-list">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{item.label}</Form.Label>
                                                    {item.format === 'LONGTEXT' && (
                                                        <EditorProvider>
                                                            <Editor
                                                                name={`exam-${item.code}`}
                                                                value={ExamModel().getResult(item.code, results)?.value}
                                                                onChange={(e) => {
                                                                    let result: ExamResultType | boolean = false;
                                                                    for (let i in results) {
                                                                        if (results[i].code === item.code) {
                                                                            results[i].value = e.target.value;
                                                                            result = results[i];
                                                                        }
                                                                        setResults([...results]);
                                                                    }

                                                                    if (typeof result === "boolean") {
                                                                        setResults([...results, {
                                                                            code: item.code,
                                                                            value: e.target.value,
                                                                            id: -1,
                                                                            exam_id: exam?.id
                                                                        }])
                                                                    }
                                                                }}
                                                            >
                                                                <Toolbar>
                                                                    <BtnBold />
                                                                    <BtnItalic />
                                                                    <BtnUnderline />
                                                                    <BtnStrikeThrough />
                                                                    <Separator />
                                                                    <BtnNumberedList />
                                                                    <BtnBulletList />
                                                                </Toolbar>
                                                            </Editor>
                                                        </EditorProvider>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        );
                                    }
                                })}
                                {type && exam && (
                                    <ExamFiles
                                        files={files}
                                        exam={exam}
                                        onSave={loadFiles}
                                    />
                                )}
                            </Row>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                                <FormattedMessage {...LANG_COMMON_CANCEL} />
                            </Button>
                            <Button variant="primary" type={"submit"} disabled={loading} className="btn-custom bg-custom-gradient">
                                {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> <FormattedMessage {...LANG_COMMON_LOADING} /></> : <FormattedMessage {...LANG_COMMON_SAVE} />}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default ExamModal;