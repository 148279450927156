import React, { FC, useContext } from "react";
import { Badge, Button, Card, Image } from "react-bootstrap";
import { ImageUploadProvider, ImageUploadProviderContext } from "../../../providers/ImageUploadProvider";
import ImageUploader from "../../../components/ImageUploader";
import clsx from "clsx";
import { useAuth } from "../../../hooks/useAuth";
import moment from "moment";
import { useIntl } from "react-intl";
import { LANG_COMMON_CHOOSE, LANG_COMMON_VIEW, LANG_PERSON_AGE, LANG_PERSON_EYES_COLOR, LANG_PERSON_HAIR_COLOR, LANG_PERSON_HAIR_TYPE, LANG_PERSON_HEIGHT, LANG_PERSON_RACE, LANG_PERSON_SKIN_COLOR } from "../../../lang";
import { OptionsContext } from "../../../providers/OptionsContextProvider";
import { PersonType } from "../../../types/PersonType";
import { useUtil } from "../../../hooks/useUtil";
import "./person-card.css";

interface Props {
    person: PersonType
    onSelect: (person: PersonType) => void
}

const PersonCard: FC<Props> = ({ person, onSelect }) => {
    const intl = useIntl();
    const util = useUtil();

    return (
        <div className="paired-person-card">
            <ImageUploadProvider url={`/api/person/${person.id}/photo`} onChange={() => { }}>
                <ImageUploadProviderContext.Consumer>
                    {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download }) => (
                        <ImageUploader
                            hasUploadError={hasUploadError}
                            hasDownloadError={hasDownloadError}
                            download={download}
                            loading={loading}
                            height={304}
                            width={304}
                            url={url}
                            onChange={onChange}
                            onRemove={onRemove}
                            uploadUrl={`/api/person/${person.id}/photo`}
                            edit={false}
                            removeUrl={""}
                            photoLabel={`${person.name}`}
                            noGradient={true}
                            stared={false}
                            showCheckbox={false}
                        />
                    )}
                </ImageUploadProviderContext.Consumer>
            </ImageUploadProvider>
            <div className={'p-3'}>
                <ul>
                    <li>CPF: {person.cpf}</li>
                    {person.birth_date && (
                        <>
                            <li>Idade: {util.calculateAge(person.birth_date, 'YYYY-MM-DD')} anos</li>
                        </>
                    )}
                </ul>
                <div className="d-grid gap-2">
                    <Button
                        variant="primary"
                        className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100"
                        onClick={() => onSelect(person)}
                    >{intl.formatMessage(LANG_COMMON_VIEW)}</Button>
                </div>
            </div>
        </div>
    );
}

export default PersonCard;