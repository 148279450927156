export function TreatmentReducer(state: any, action: any) {
    switch (action.type) {
        case 'setTreatment':
            return {...state, treatment: action.treatment};
        case 'setResults':
            return {...state, results: action.results};
        case 'setLoading':
            return {...state, loading: action.loading};
        case 'setLoadingResults':
            return {...state, loadingResults: action.loadingResults};
        default:
            return state;
    }
}