import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Table, Breadcrumb, Form } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PersonModel } from "../../models/PersonModel";
import { CoupleType, PersonFile, PersonType } from "../../types/PersonType";
import Loader from "../../components/messages/Loader";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import PersonsSelect from "../../components/PersonsSelect";
import * as yup from "yup";
import { useFormik } from "formik";
import { OptionType } from "../../types/CommonType";
import CustomSelect from "../../components/form/CustomSelect";
import TempLayout from "../../temp/04_receptoras_isis-4.png";
import StateList from "../../components/form/StateList";
import clsx from "clsx";
import CityList from "../../components/form/CityList";
import TranslateTerms from "../../components/TranslateTerms";
import Error from "../../components/messages/Error";
import { FileUploadProvider, FileUploadProviderContext } from "../../providers/FileUploadProvider";
import FileUploader from "../../components/FileUploader";
import { FormattedMessage, useIntl } from "react-intl";
import { OptionsContext } from "../../providers/OptionsContextProvider";
import { LANG_COMMON_BACK, LANG_COMMON_CHOOSE, LANG_COMMON_DATA, LANG_COMMON_FILES, LANG_COMMON_NO, LANG_COMMON_OPTIONS, LANG_COMMON_OTHER_DATA, LANG_COMMON_PATIENT, LANG_COMMON_PATIENTS, LANG_COMMON_SAVE, LANG_COMMON_YES, LANG_PERSON_HAS_CHILDREN, LANG_PERSON_HOBBIES, LANG_PERSON_OCCUPATION, LANG_PERSON_SCHOOLING } from "../../lang";
import { CoupleModel } from "../../models/CoupleModel";
import CoupleEditContainer from "./CoupleEditContainer";
import { CoupleMedicalRecordContext, CoupleMedicalRecordContextProvider } from "../../providers/CoupleMedicalRecordContextProvider";
import CoupleMedicalRecordContainer from "./CoupleMedicalRecordContainer";

const CoupleMedicalRecordPage = () => {
    const intl = useIntl();
    const options = useContext(OptionsContext);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [couple, setCouple] = useState<CoupleType>();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [shouldSubmitAddress, setShouldSubmitAddress] = useState(false);
    const [showErrorValidation, setShowErrorValidation] = useState(false);
    const [files, setFiles] = useState<PersonFile[] | undefined>([]);

    const navigate = useNavigate();
    const { id } = useParams();    

    return (
        <>
            {loading && (<Loader fullscreen={true} />)}
            <Error
                show={showErrorValidation}
                onHide={() => {
                    setShowErrorValidation(false);
                }}
                message={`Preencha corretamente os campos marcados em vermelho e tente novamente.`} />
            <div className="bg-pattern">
                    <Container style={{ 'paddingBottom': '200px' }}>
                        {id != undefined && (
                            <CoupleMedicalRecordContextProvider couple_id={id}>
                                <CoupleMedicalRecordContainer/>
                            </CoupleMedicalRecordContextProvider>
                        )}
                    </Container>
                </div>
        </>
    );
}

export default CoupleMedicalRecordPage;