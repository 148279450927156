import { CouplePaginate, CoupleType, PairedPersonType, PairingResponse, PersonPaginate, PersonType } from "../types/PersonType";
import { useAPI } from "../hooks/useAPI";
import { OptionType, ResponseErrorType } from "../types/CommonType";
import { MedicalRecordEvolutionType, MedicalRecordType } from "../types/MedicalRecordType";
import { MedicationMetaType, TreatmentResultType, TreatmentType } from "../types/TreatmentType";
import { ExamResponse, ExamResultType, ExamType } from "../types/ExamType";
import { DocumentModelType, DocumentType } from "../types/DocumentType";

export function DocumentModel() {
    const { api } = useAPI();

    const create = async (document: DocumentType, medical_record_id: number) => {
        return new Promise<ResponseErrorType | DocumentType>((resolve) => {
            api()
                .post(`/api/medical-record/${medical_record_id}/document`, document)
                .then((resp) => {
                    resolve(resp.data.document);
                })
                .catch((err) => {
                    resolve({ code: err.response.status, message: err.response.data.message, error: true })
                });
        });
    }

    const update = async (document_id: number, document: DocumentType) => {
        return new Promise<ResponseErrorType | DocumentType>((resolve) => {
            api()
                .put(`/api/medical-record/${document.medical_record_id}/document/${document_id}`, document)
                .then((resp) => {
                    resolve(resp.data.document);
                })
                .catch((err) => {
                    resolve({ code: err.response.status, message: err.response.data.message, error: true })
                });
        });
    }

    const createEvolution = async (medical_record_id: number, medical_record_evolution: MedicalRecordEvolutionType) => {
        return new Promise<ResponseErrorType | MedicalRecordEvolutionType>((resolve) => {
            api()
                .post(`/api/medical-record/${medical_record_id}/evolution`, medical_record_evolution)
                .then((resp) => {
                    resolve(resp.data.medical_record_evolution);
                })
                .catch((err) => {
                    resolve({ code: err.response.status, message: err.response.data.message, error: true })
                });
        });
    }

    const createResult = async (result: TreatmentResultType, treatment: TreatmentType) => {
        return new Promise<ResponseErrorType | TreatmentResultType>((resolve) => {
            api()
                .post(`/api/medical-record/${treatment.medical_record_id}/treatment/${treatment.id}/result`, result)
                .then((resp) => {
                    resolve(resp.data.result);
                })
                .catch((err) => {
                    resolve({ code: err.response.status, message: err.response.data.message, error: true })
                });
        });
    }

    const updateResult = async (result: TreatmentResultType, treatment: TreatmentType) => {
        return new Promise<ResponseErrorType | TreatmentResultType>((resolve) => {
            api()
                .put(`/api/medical-record/${treatment.medical_record_id}/treatment/${treatment.id}/result/${result.id}`, result)
                .then((resp) => {
                    resolve(resp.data.result);
                })
                .catch((err) => {
                    resolve({ code: err.response.status, message: err.response.data.message, error: true })
                });
        });
    }

    const list = async (medical_record_id: number) => {
        return new Promise<boolean | DocumentType[]>((resolve) => {
            api()
                .get(`/api/medical-record/${medical_record_id}/documents`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const get = async (medical_record_id: number, exam_id: number) => {
        return new Promise<ExamType | boolean>((resolve) => {
            api()
                .get(`/api/medical-record/${medical_record_id}/exam/${exam_id}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const getResults = async (medical_record_id: number, treatment_id: number) => {
        return new Promise<TreatmentResultType[] | boolean>((resolve) => {
            api()
                .get(`/api/medical-record/${medical_record_id}/treatment/${treatment_id}/results`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const getModels = async (type: string) => {
        return new Promise<DocumentModelType[] | boolean>((resolve) => {
            api()
                .get(`/api/configuration/document/${type}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const remove = async (couple_id: number) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/couple/${couple_id}`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    const removeResult = async (treatment: TreatmentType, treatment_result: TreatmentResultType) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/medical-record/${treatment.medical_record_id}/treatment/${treatment.id}/result/${treatment_result.id}`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    const formatCoupleName = (couple: CoupleType) => {
        return `${couple.primary.name}` + (couple.secondary ? ` <> ${couple.secondary.name}` : '');
    }

    const getTreatmentTypeOptions = (): Array<OptionType> => {
        return [
            { label: "FIV", value: "FIV" },
            { label: "ICSI", value: "ICS1" }
        ];
    }

    const getSemenOriginOptions = (): Array<OptionType> => {
        return [
            { label: "Ejaculado", value: "EJA" },
            { label: "MESA", value: "MES" }
        ];
    }

    const getOvumOriginOptions = (): Array<OptionType> => {
        return [
            { label: "Óvulos próprios", value: "OPRO" },
            { label: "Óvulos doados", value: "ODOA" }
        ];
    }

    const getDocumentTypes = (): Array<OptionType> => {
        return [
            { label: "Receitas", value: "RECE" },
            { label: "Atestados", value: "ATES" },
            { label: "Exames", value: "EXAM" },
        ];
    }

    const getResult = (code: string, results: ExamResultType[]): ExamResultType | undefined => {
        for (let i in results) {
            let result = results[i];
            if (result.code === code) {
                return result;
            }
        }
    }

    return {
        create,
        update,
        remove,
        list,
        get,
        getResults,
        formatCoupleName,
        getTreatmentTypeOptions,
        getSemenOriginOptions,
        getOvumOriginOptions,
        createEvolution,
        getDocumentTypes,
        getResult,
        createResult,
        updateResult,
        removeResult,
        getModels
    };
}