import React, {FC, useEffect, useReducer} from "react";
import { DocumentContextType } from "../types/DocumentType";
import { DocumentModel } from "../models/DocumentModel";
import { DocumentReducer } from "../reducers/DocumentReducer";

interface Props {
    medical_record_id: number
}

const DefaultData: DocumentContextType = {
    documents: [],    
    loading: false,
    loadDocuments: (medical_record_id: number) => {}   
};
const DocumentContext = React.createContext<DocumentContextType>(DefaultData);
const DocumentContextProvider: FC <Props> = ({children, medical_record_id}) => {    

    const loadDocuments = (medical_record_id: number) => {
        dispatch({type: 'setLoading', loading: true});
        DocumentModel().list(medical_record_id).then((resp) => {
            dispatch({type: 'setLoading', loading: false});
            if(typeof resp !== "boolean") {
                dispatch({type: 'setDocuments', documents: resp});
            }
        });
    }

    useEffect(() => {
        loadDocuments(medical_record_id);
    }, []);

    const [data, dispatch] = useReducer(DocumentReducer, {
        ...DefaultData,
        loadDocuments: loadDocuments
    });
    return (<DocumentContext.Provider value={data}>{children}</DocumentContext.Provider>);
}

export {DocumentContextProvider, DocumentContext}