import {CouplePaginate, CoupleType, PairedPersonType, PairingResponse, PersonPaginate, PersonType} from "../types/PersonType";
import {useAPI} from "../hooks/useAPI";
import {OptionType, ResponseErrorType} from "../types/CommonType";
import { MedicalRecordEvolutionType, MedicalRecordType } from "../types/MedicalRecordType";

export function MedicalRecordModel() {
    const {api} = useAPI();

    const create = async (couple: CoupleType) => {
        return new Promise<ResponseErrorType | CoupleType>((resolve) => {            
            api()
                .post("/api/couple", couple)
                .then((resp) => {
                    resolve(resp.data.couple);
                })
                .catch((err) => {
                    resolve({code: err.response.status, message: err.response.data.message, error: true})
                });
        });
    }

    const createEvolution = async (medical_record_id: number, medical_record_evolution: MedicalRecordEvolutionType) => {
        return new Promise<ResponseErrorType | MedicalRecordEvolutionType>((resolve) => {            
            api()
                .post(`/api/medical-record/${medical_record_id}/evolution`, medical_record_evolution)
                .then((resp) => {
                    resolve(resp.data.medical_record_evolution);
                })
                .catch((err) => {
                    resolve({code: err.response.status, message: err.response.data.message, error: true})
                });
        });
    }

    const update = async (couple_id: number, couple: CoupleType) => {
        return new Promise<boolean | CoupleType>((resolve) => {
            api()
                .put(`/api/couple/${couple_id}`, couple)
                .then((resp) => {
                    resolve(resp.data.couple);
                })
                .catch(() => resolve(false));
        });
    }

    const list = async (business_id: number | undefined = undefined, options: any | undefined = undefined, pageNumber: number=1, perPage: number=10) => {
        return new Promise<boolean | CouplePaginate>((resolve) => {
            api()
                .get(`/api/couple?business_id=${(business_id !== undefined) ? business_id : ""}&count=${perPage}&page=${pageNumber}&${new URLSearchParams(options).toString()}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const get = async (couple_id: number | string) => {
        return new Promise<MedicalRecordType | boolean>((resolve) => {
            api()
                .get(`/api/couple/${couple_id}/medical-record`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }    

    const remove = async (couple_id: number) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/couple/${couple_id}`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    const formatCoupleName = (couple: CoupleType) => {
        return `${couple.primary.name}` + (couple.secondary ? ` <> ${couple.secondary.name}`: '');
    }

    const getDiagnosisOptions = () : Array<OptionType> => {
        return [
            {label: "Infertilidade masculina", value: "IMA"},
            {label: "Varicocele", value: "VAR"}
        ];
    }

    return {
        create,
        update,
        remove,
        list,
        get,
        formatCoupleName,
        getDiagnosisOptions,
        createEvolution
    };
 }