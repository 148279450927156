import React, { FC, useState } from "react";
import { Form, Modal, Button, Alert, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import { CoupleType, PersonType } from "../../../../types/PersonType";
import { PersonModel } from "../../../../models/PersonModel";
import { isResponseError, OptionType, ResponseErrorType } from "../../../../types/CommonType";
import TranslateTerms from "../../../../components/TranslateTerms";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_CANCEL, LANG_COMMON_DIAGNOSIS, LANG_COMMON_EVOLUTION, LANG_COMMON_LEGAL_ID, LANG_COMMON_LOADING, LANG_COMMON_MEDICAL_RECORD_NUMBER, LANG_COMMON_NAME, LANG_COMMON_NEW, LANG_COMMON_PATIENT, LANG_COMMON_SAVE, LANG_ERROR, LANG_WARNING_FILL_ALL } from "../../../../lang";
import InputMask from 'react-input-mask';
import clsx from "clsx";
import { CoupleModel } from "../../../../models/CoupleModel";
import { MedicalRecordEvolutionType, MedicalRecordType } from "../../../../types/MedicalRecordType";
import { MedicalRecordModel } from "../../../../models/MedicalRecordModel";
import { DefaultUser } from "../../../../providers/DefaultData";
import { BtnBold, BtnBulletList, BtnItalic, BtnNumberedList, BtnStrikeThrough, BtnUnderline, BtnUndo, Editor, EditorProvider, Separator, Toolbar } from "react-simple-wysiwyg";
import { TreatmentMedicationType, TreatmentType } from "../../../../types/TreatmentType";
import { TreatmentModel } from "../../../../models/TreatmentModel";
import CustomSelect from "../../../../components/form/CustomSelect";
import moment from "moment";

interface Props {
    show: boolean
    onHide: () => void
    onSave: (treatment: TreatmentType) => void
    treatment: TreatmentType | null
    medical_record: MedicalRecordType
}

const TreatmentAddModal: FC<Props> = ({ show, onHide, onSave, treatment, medical_record }) => {
    const schema = yup.object().shape({
        type: yup.string().required(),
        origin_semen: yup.string().required(),
        origin_ovum: yup.string().required(),
        eoc_date: yup.string().required(),
        dum_date: yup.string().required()
    });
    const [formWasSubmitted, setFormWasSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const intl = useIntl();
    const DefaultErrorMsg = intl.formatMessage(LANG_ERROR);
    const [errorMsg, setErrorMsg] = useState(DefaultErrorMsg);
    const [medications, setMedications] = useState<TreatmentMedicationType[]>((treatment?.medications) ? treatment.medications : []);
    const [medication, setMedication] = useState<string | undefined>(undefined);

    const initialTreatment: TreatmentType = (treatment) ?
        {
            ...treatment,
            puncture_date: (treatment.puncture_date) ? moment(treatment.puncture_date).format("DD/MM/YYYY") : ""
        }
        : {
            id: -1,
            type: "",
            origin_semen: "",
            origin_ovum: "",
            medications: [],
            created_at: "",
            medical_record_id: medical_record.id,
            eoc_date: "",
            dum_date: ""
        };

    const onSubmit = async (values: TreatmentType) => {
        setLoading(true);
        setFormWasSubmitted(false);
        setErrorMsg(DefaultErrorMsg);
        setHasError(false);
        // Create or update
        let resp: TreatmentType | ResponseErrorType = (treatment === null) ?
            await TreatmentModel().create({
                ...values,
                medications: medications,
                puncture_date: (values.puncture_date) ? moment(values.puncture_date, "DD/MM/YYYY").format("YYYY-MM-DD") : ""
            }, medical_record.id)
            : await TreatmentModel().update(treatment.id, {
                ...values,
                medications: medications,
                puncture_date: (values.puncture_date) ? moment(values.puncture_date, "DD/MM/YYYY").format("YYYY-MM-DD") : ""
            });
        setLoading(false);
        // Saving result
        if (!isResponseError(resp)) {
            onSave(resp as TreatmentType);
        } else {
            setHasError(true);
            setErrorMsg((resp as ResponseErrorType).message);
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                setFormWasSubmitted(false);
            }}
            className="modal-default"
            size="lg"
        >
            <Formik
                validationSchema={schema}
                onSubmit={onSubmit}
                initialValues={initialTreatment}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    setFieldValue,
                    isValid,
                    errors }) => (
                    <Form noValidate onSubmit={(e) => {
                        setFormWasSubmitted(true);
                        handleSubmit(e);
                    }}>
                        <Modal.Header closeButton closeVariant="white">
                            <Modal.Title>
                                Tratamento
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(!isValid && formWasSubmitted) && (<Alert variant={'danger'}><FormattedMessage {...LANG_WARNING_FILL_ALL} /></Alert>)}
                            {hasError && (<Alert variant={'danger'}>{errorMsg}</Alert>)}
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Tipo de tratamento</Form.Label>
                                        <CustomSelect
                                            options={TreatmentModel().getTreatmentTypeOptions()}
                                            name="type"
                                            onChange={(o: OptionType) => {
                                                setFieldValue('type', o.value);
                                            }}
                                            placeholder="Selecione..."
                                            value={values.type}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Origem do Sêmen</Form.Label>
                                        <CustomSelect
                                            options={TreatmentModel().getSemenOriginOptions()}
                                            name="origin_semen"
                                            onChange={(o: OptionType) => {
                                                setFieldValue('origin_semen', o.value);
                                            }}
                                            placeholder="Selecione..."
                                            value={values.origin_semen}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Origem dos Óvulos</Form.Label>
                                        <CustomSelect
                                            options={TreatmentModel().getOvumOriginOptions()}
                                            name="origin_ovum"
                                            onChange={(o: OptionType) => {
                                                setFieldValue('origin_ovum', o.value);
                                            }}
                                            placeholder="Selecione..."
                                            value={values.origin_ovum}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Data de início EOC</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="eoc_date"
                                            value={values.eoc_date}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Data da última menstruação</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="dum_date"
                                            value={values.dum_date}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="title-divider">
                                        <h6>MEDICAÇÕES</h6>
                                        <hr />
                                    </div>
                                </Col>

                                {medications.map((item, index) => (
                                    <>
                                        <Col lg={4}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Medicação</Form.Label>
                                                <CustomSelect
                                                    options={TreatmentModel().getMedicationsOptions()}
                                                    key={`medication-${item.medication}`}
                                                    name={`medication-${item.medication}`}
                                                    onChange={(o: OptionType) => {
                                                        medications[index].medication = o.value;
                                                        setMedications([...medications]);
                                                    }}
                                                    placeholder="Selecione..."
                                                    value={item.medication}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Dosagem</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={item.dosage}
                                                    onChange={(e) => {
                                                        medications[index].dosage = e.target.value;
                                                        setMedications([...medications]);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={2}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Data Inicio</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    value={item.date_start}
                                                    onChange={(e) => {
                                                        medications[index].date_start = e.target.value;
                                                        setMedications([...medications]);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={2}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Data Fim</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    value={item.date_end}
                                                    onChange={(e) => {
                                                        medications[index].date_end = e.target.value;
                                                        setMedications([...medications]);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={1}>
                                            <div className="mt-3">
                                                <a href="#" className="btn btn-light" onClick={(e) => {
                                                    medications.splice(index, 1);
                                                    console.log(medications, index);
                                                    setMedications([...medications]);
                                                }}>
                                                    <i className="bi bi-trash"></i>
                                                </a>
                                            </div>
                                        </Col>
                                    </>
                                ))}

                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Button variant="primary" className="btn-custom bg-custom-gradient"
                                            onClick={() => {
                                                setMedications([...medications, {
                                                    id: -1,
                                                    medication: '',
                                                    treatment_id: (treatment) ? treatment.id : -1,
                                                    date_start: '',
                                                    date_end: '',
                                                    dosage: 0
                                                }]);
                                            }}
                                        >
                                            Adicionar medicação
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="title-divider">
                                        <h6>TRIGGER</h6>
                                        <hr />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Data</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="trigger_date"
                                            value={values.trigger_date}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Horário</Form.Label>
                                        <InputMask
                                            value={values.trigger_time}
                                            onChange={handleChange}
                                            name={"trigger_time"}
                                            type="text"
                                            mask="99:99"
                                            className={clsx("form-control", {
                                                // "is-invalid": formik.touched.cpf && formik.errors.cpf,
                                                //"is-valid": !formik.errors.cpf && formik.touched.cpf
                                            })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Medicação utilizada</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={values.trigger_medication}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="title-divider">
                                        <h6>PUNÇÃO</h6>
                                        <hr />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Data</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="puncture_date"
                                            value={values.puncture_date}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Horário</Form.Label>
                                        <InputMask
                                            value={values.puncture_time}
                                            onChange={handleChange}
                                            name={"puncture_time"}
                                            type="text"
                                            mask="99:99"
                                            className={clsx("form-control", {
                                                // "is-invalid": formik.touched.cpf && formik.errors.cpf,
                                                //"is-valid": !formik.errors.cpf && formik.touched.cpf
                                            })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Clínica</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            placeholder="Clínica"
                                            value={values.puncture_clinic}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                                <FormattedMessage {...LANG_COMMON_CANCEL} />
                            </Button>
                            <Button variant="primary" type={"submit"} disabled={loading} className="btn-custom bg-custom-gradient">
                                {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> <FormattedMessage {...LANG_COMMON_LOADING} /></> : <FormattedMessage {...LANG_COMMON_SAVE} />}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default TreatmentAddModal;