import React, { FC, useState } from "react";
import { Form, Modal, Button, Alert, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import { CoupleType, PersonType } from "../../../../types/PersonType";
import { PersonModel } from "../../../../models/PersonModel";
import { isResponseError, OptionType, ResponseErrorType } from "../../../../types/CommonType";
import TranslateTerms from "../../../../components/TranslateTerms";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_CANCEL, LANG_COMMON_DIAGNOSIS, LANG_COMMON_EVOLUTION, LANG_COMMON_LEGAL_ID, LANG_COMMON_LOADING, LANG_COMMON_MEDICAL_RECORD_NUMBER, LANG_COMMON_NAME, LANG_COMMON_NEW, LANG_COMMON_PATIENT, LANG_COMMON_SAVE, LANG_ERROR, LANG_WARNING_FILL_ALL } from "../../../../lang";
import InputMask from 'react-input-mask';
import clsx from "clsx";
import { CoupleModel } from "../../../../models/CoupleModel";
import { MedicalRecordEvolutionType, MedicalRecordType } from "../../../../types/MedicalRecordType";
import { MedicalRecordModel } from "../../../../models/MedicalRecordModel";
import { DefaultUser } from "../../../../providers/DefaultData";
import { BtnBold, BtnBulletList, BtnItalic, BtnNumberedList, BtnStrikeThrough, BtnUnderline, BtnUndo, Editor, EditorProvider, Separator, Toolbar } from "react-simple-wysiwyg";
import { MedicationMetaType, TreatmentResultType, TreatmentType } from "../../../../types/TreatmentType";
import { TreatmentModel } from "../../../../models/TreatmentModel";
import CustomSelect from "../../../../components/form/CustomSelect";
import moment from "moment";
import { useUtil } from "../../../../hooks/useUtil";

interface Props {
    show: boolean
    onHide: () => void
    onSave: (treatment: TreatmentResultType) => void
    result: TreatmentResultType | null
    treatment: TreatmentType
}

const TreatmentResultAddModal: FC<Props> = ({ show, onHide, onSave, result, treatment }) => {
    const schema = yup.object().shape({
        date: yup.string().required()
    });
    const [formWasSubmitted, setFormWasSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const intl = useIntl();
    const DefaultErrorMsg = intl.formatMessage(LANG_ERROR);
    const [errorMsg, setErrorMsg] = useState(DefaultErrorMsg);
    const { getOption } = useUtil();


    const [medications, setMedications] = useState<MedicationMetaType[]>((treatment?.medications !== undefined) ? treatment?.medications?.map((item) => {
        if(result) {
            return { 'id': item.medication, 'value': ((result.medications) ? TreatmentModel().getMedicationMeta(item.medication, JSON.parse(result.medications))?.value : "")};
        } else {
            return { 'id': item.medication, 'value': ""};
        }
    }) : []);

    const initialResult: TreatmentResultType = (result) ?
        {
            ...result
        }
        : {
            id: -1,
            treatment_id: treatment.id,
            date: moment(new Date()).format("YYYY-MM-DD")
        };

    const onSubmit = async (values: TreatmentResultType) => {
        setLoading(true);
        setFormWasSubmitted(false);
        setErrorMsg(DefaultErrorMsg);
        setHasError(false);
        // Create or update
        let resp: TreatmentResultType | ResponseErrorType = (result === null) ?
            await TreatmentModel().createResult({
                ...values,
                medications: JSON.stringify(medications),
                date: (values.date) ? moment(values.date).format("YYYY-MM-DD") : ""
            }, treatment)
            : await TreatmentModel().updateResult({
                ...values,
                medications: JSON.stringify(medications),
                date: (values.date) ? moment(values.date).format("YYYY-MM-DD") : ""
            }, treatment);
        setLoading(false);
        // Saving result
        if (!isResponseError(resp)) {
            onSave(resp as TreatmentResultType);
        } else {
            setHasError(true);
            setErrorMsg((resp as ResponseErrorType).message);
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                setFormWasSubmitted(false);
            }}
            className="modal-default"
            size="lg"
        >
            <Formik
                validationSchema={schema}
                onSubmit={onSubmit}
                initialValues={initialResult}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    setFieldValue,
                    isValid,
                    errors }) => (
                    <Form noValidate onSubmit={(e) => {
                        setFormWasSubmitted(true);
                        handleSubmit(e);
                    }}>
                        <Modal.Header closeButton closeVariant="white">
                            <Modal.Title>
                                Resultados
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(!isValid && formWasSubmitted) && (<Alert variant={'danger'}><FormattedMessage {...LANG_WARNING_FILL_ALL} /></Alert>)}
                            {hasError && (<Alert variant={'danger'}>{errorMsg}</Alert>)}
                            <Row>
                                <Col lg={2}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Data</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="date"
                                            disabled={result !== null}
                                            value={values.date}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Endometrio</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="endometrium"
                                            value={values.endometrium}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Total (&gt;14)</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="total_gt_14"
                                            value={values.total_gt_14}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Óvario Direito</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="right_ovary"
                                            value={values.right_ovary}
                                            onChange={handleChange}
                                            rows={5}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Óvario Esquerdo</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="left_ovary"
                                            value={values.left_ovary}
                                            onChange={handleChange}
                                            rows={5}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <hr />
                                </Col>
                                <Col lg={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>E2</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="e2"
                                            value={values.e2}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>LH</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lh"
                                            value={values.lh}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>P4</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="p4"
                                            value={values.p4}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Outros</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="others"
                                            value={values.others}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <hr />
                                </Col>
                                {treatment?.medications?.map((item, index) => (
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{getOption(item.medication, TreatmentModel().getMedicationsOptions())?.label}</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={`med-${item}`}
                                                value={TreatmentModel().getMedicationMeta(item.medication, medications)?.value}
                                                onChange={(e) => {
                                                    medications[index] = { id: item.medication, value: e.target.value };
                                                    setMedications([...medications]);                                                    
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                ))}
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                                <FormattedMessage {...LANG_COMMON_CANCEL} />
                            </Button>
                            <Button variant="primary" type={"submit"} disabled={loading} className="btn-custom bg-custom-gradient">
                                {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> <FormattedMessage {...LANG_COMMON_LOADING} /></> : <FormattedMessage {...LANG_COMMON_SAVE} />}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default TreatmentResultAddModal;