import {PairedPersonType, PairingResponse, PersonType} from "../types/PersonType";
import {useAPI} from "../hooks/useAPI";
import {OptionType, ResponseErrorType} from "../types/CommonType";
import {RodType} from "../types/RodType";
import {BornAliveType, PairingType} from "../types/PairingType";
import {UserType} from "../types/UserType";

export function PairingModel() {
    const {api} = useAPI();

    const create = async (personId: number | string, pairing: PairingType) => {
        return new Promise<boolean | PairingType>((resolve) => {
            api()
                .post(`/api/person/${personId}/pairing`, pairing)
                .then((resp) => {
                    resolve(resp.data.pairing);
                })
                .catch(() => resolve(false));
        });
    }

    const update = async (person_id: number, rod_id: number, rod: RodType) => {
        return new Promise<boolean | RodType>((resolve) => {
            api()
                .put(`/api/person/${person_id}/rod/${rod_id}`, rod)
                .then((resp) => {
                    resolve(resp.data.rod);
                })
                .catch(() => resolve(false));
        });
    }

    const list = async (business_id: number | undefined = undefined, search: string | undefined = "") => {
        return new Promise<boolean | Array<PairingType>>((resolve) => {
            api()
                .get(`/api/pairing?business_id=${(business_id !== undefined) ? business_id : ""}&${new URLSearchParams(search).toString()}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const get = async (pairing_id: string) => {
        return new Promise<PairingType | boolean>((resolve) => {
            api()
                .get(`/api/pairing/${pairing_id}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const remove = async (person_id: number, pairing_id: number) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/person/${person_id}/pairing/${pairing_id}`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    const createBornAlive = async (pairingId: number, bornAlive: BornAliveType) => {
        return new Promise<boolean | BornAliveType>((resolve) => {
            api()
                .post(`/api/pairing/${pairingId}/born-alive`, bornAlive)
                .then((resp) => {
                    resolve(resp.data.born_alive);
                })
                .catch(() => resolve(false));
        });
    }

    const removeBornAlive = async (pairing_id: number, born_alive_id: number) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/pairing/${pairing_id}/born-alive/${born_alive_id}`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    return {
        create,
        update,
        remove,
        list,
        get,
        createBornAlive,
        removeBornAlive
    };
 }