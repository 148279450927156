import React, { FC, useContext } from "react";
import { Badge, Button, Card, Col, Image, Row } from "react-bootstrap";
import { ImageUploadProvider, ImageUploadProviderContext } from "../../../providers/ImageUploadProvider";
import ImageUploader from "../../../components/ImageUploader";
import clsx from "clsx";
import { useAuth } from "../../../hooks/useAuth";
import moment from "moment";
import { useIntl } from "react-intl";
import { LANG_COMMON_CHOOSE, LANG_COMMON_VIEW, LANG_PERSON_AGE, LANG_PERSON_EYES_COLOR, LANG_PERSON_HAIR_COLOR, LANG_PERSON_HAIR_TYPE, LANG_PERSON_HEIGHT, LANG_PERSON_RACE, LANG_PERSON_SKIN_COLOR } from "../../../lang";
import { OptionsContext } from "../../../providers/OptionsContextProvider";
import { PersonType } from "../../../types/PersonType";
import { useUtil } from "../../../hooks/useUtil";
import "./mre.css";
import { MedicalRecordEvolutionType } from "../../../types/MedicalRecordType";
import { MedicalRecordModel } from "../../../models/MedicalRecordModel";
import { PersonModel } from "../../../models/PersonModel";

interface Props {
    medical_record_evolution: MedicalRecordEvolutionType
    onSelect: (person: PersonType) => void
}

const MedicalRecordEvolutionEntry: FC<Props> = ({ medical_record_evolution, onSelect }) => {
    const intl = useIntl();
    const util = useUtil();

    return (
        <Row>
            <Col>
                <div className="mre-bullet" />
                <div className="mre-line" />
            </Col>
            <Col lg={11}>
                <div className="mre-content">
                    <h5>{medical_record_evolution.user?.name}</h5>
                    <div className="mre-date">{moment(medical_record_evolution.created_at).format("DD/MM/YYYY")} às {moment(medical_record_evolution.created_at).format("HH:mm")}</div>
                    <p dangerouslySetInnerHTML={{ __html: medical_record_evolution.content }} />
                    {medical_record_evolution.diagnosis && (
                        <p><b>Diagnóstico:</b> {PersonModel().getOption(medical_record_evolution.diagnosis, MedicalRecordModel().getDiagnosisOptions())?.label}</p>
                    )}
                </div>
            </Col>
        </Row>
    );
}

export default MedicalRecordEvolutionEntry;