import React, { FC, useEffect, useState } from "react";
import { Form, Modal, Button, Alert, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import { isResponseError, OptionType, ResponseErrorType } from "../../../types/CommonType";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_CANCEL, LANG_COMMON_DIAGNOSIS, LANG_COMMON_EVOLUTION, LANG_COMMON_LEGAL_ID, LANG_COMMON_LOADING, LANG_COMMON_MEDICAL_RECORD_NUMBER, LANG_COMMON_NAME, LANG_COMMON_NEW, LANG_COMMON_PATIENT, LANG_COMMON_SAVE, LANG_ERROR, LANG_WARNING_FILL_ALL } from "../../../lang";
import InputMask from 'react-input-mask';
import clsx from "clsx";
import { MedicalRecordEvolutionType, MedicalRecordType } from "../../../types/MedicalRecordType";
import { ExamFile, ExamOptionType, ExamResultType, ExamType } from "../../../types/ExamType";
import { ExamModel } from "../../../models/ExamModel";
import { FileUploadProvider, FileUploadProviderContext } from "../../../providers/FileUploadProvider";
import FileUploader from "../../../components/FileUploader";

interface Props {
    onSave: () => void
    files: ExamFile[]
    exam: ExamType
}

const ExamFiles: FC<Props> = ({ onSave, files, exam }) => {
    const intl = useIntl();

    useEffect(() => {}, [files, exam]);

    return (
        <Col lg={{ 'span': 12 }}>
            <Row>
                <Col lg={12}>
                    <div className="title-divider">
                        <h6>ARQUIVOS</h6>
                        <hr />
                    </div>
                </Col>
                <Col lg={12}>
                    <div className="col-sm-12">
                        {files?.map((examFile) => (
                            <>
                                <FileUploadProvider url={`/api/medical-record/${exam.medical_record_id}/exam/${exam?.id}/file/${examFile.file_id}`} onChange={onSave} key={examFile.file_id}>
                                    <FileUploadProviderContext.Consumer>
                                        {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, uploadErrorMessage }) => (
                                            <FileUploader
                                                hasUploadError={hasUploadError}
                                                hasDownloadError={false}
                                                uploadErrorMessage={uploadErrorMessage}
                                                download={download}
                                                loading={loading}
                                                url={url}
                                                edit={false}
                                                onChange={onChange}
                                                onRemove={onRemove}
                                                uploadUrl={``}
                                                removeUrl={`/api/medical-record/${exam.medical_record_id}/exam/${exam?.id}/file/${examFile.file_id}`}
                                                remove={true}
                                                name={examFile.file.name}
                                            />
                                        )}
                                    </FileUploadProviderContext.Consumer>
                                </FileUploadProvider>
                            </>
                        ))}
                    </div>
                </Col>
                <Col lg={12}>
                    <FileUploadProvider url={undefined} onChange={() => { }} onDone={onSave}>
                        <FileUploadProviderContext.Consumer>
                            {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, uploadErrorMessage }) => (
                                <FileUploader
                                    hasUploadError={hasUploadError}
                                    hasDownloadError={false}
                                    uploadErrorMessage={uploadErrorMessage}
                                    download={download}
                                    loading={loading}
                                    url={url}
                                    onChange={onChange}
                                    onRemove={onRemove}
                                    uploadUrl={`/api/medical-record/${exam.medical_record_id}/exam/${exam?.id}/file`}
                                    removeUrl={""} />
                            )}
                        </FileUploadProviderContext.Consumer>
                    </FileUploadProvider>
                </Col>
            </Row>
        </Col>
    )
}

export default ExamFiles;