import React, {FC, useEffect, useState} from "react";
import Header from "./layout/Header";
import {Link, LinkProps, Navigate, useLocation, useMatch, useNavigate, useResolvedPath} from "react-router-dom";
import clsx from "clsx";
import {useAuth} from "../hooks/useAuth";
import {useConfig} from "../hooks/useConfig";
import TermsModal from "./TermsModal";
import Error from "./messages/Error";
import SubscriptionModal from "./Subscription/SubscriptionModal";
import PrivateRoutes from "../routes/PrivateRoutes";
import ClipLoader from "react-spinners/ClipLoader";
import PublicRoutes from "../routes/PublicRoutes";
import {AuthContext} from "../providers/AuthContextProvider";
import TranslateTerms from "./TranslateTerms";
import { FormattedMessage } from "react-intl";
import { LANG_COMMON_CLINIC, LANG_COMMON_CLINICS, LANG_COMMON_DASHBOARD, LANG_COMMON_INSTRUCTIONS, LANG_COMMON_PAIRINGS, LANG_COMMON_PATIENTS, LANG_COMMON_SUBSCRIPTION, LANG_COMMON_USERS, LANG_COMMON_USE_TERMS } from "../lang";

const LayoutInit = () => {
    useEffect(() => {}, [
        
    ]);
}

function CustomLink({ children, to, ...props }: LinkProps) {
    let location = useLocation();
    let match = (location.pathname.indexOf(to.toString()) > -1)

    return (
        <Link
            className={clsx("nav-link", {
                'active': match
            })}
            to={to}
            {...props}
        >
            {children}
        </Link>
    );
}

const Layout: FC = ({children}) => {
    const {deleteToken, hasAbility} = useAuth();
    const [showTerms, setShowTerms] = useState(false);

    return (
        <>
            <AuthContext.Consumer>
                {({termsAgreed, setTermsAgreed, user, setIsAuth}) => (
                    <>
                        {user !== undefined && (
                            <TermsModal
                                user={user}
                                show={showTerms}
                                readOnly={true}
                                onConfirm={() => {
                                    setTermsAgreed(true);
                                    setShowTerms(false);
                                }}
                                onHide={() => {
                                    setShowTerms(false);
                                }}
                            />
                        )}
                        <Header />
                        <div className={'d-grid canvas'} style={{'gridTemplateColumns': '183px 1fr', 'height': '100%'}}>
                            <div className="aside">
                                <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                                    {/*<li><CustomLink to={'/admin/dashboard'}>
                                        <div className="icon-aside icon-svg">
                                            <i className="bi bi-house-fill"></i>
                                        </div><FormattedMessage {...LANG_COMMON_DASHBOARD}/></CustomLink></li>*/}
                                    {hasAbility("SUBSCRIPTION_LIST") && (<li><CustomLink to={'/admin/subscriptions'}><div className="icon-aside">
                                        <i className="bi bi-credit-card"></i>
                                    </div><FormattedMessage {...LANG_COMMON_SUBSCRIPTION}/>s</CustomLink></li>)}
                                    <li><CustomLink to={'/admin/couples'}><div className="icon-aside">
                                        <i className="bi bi-person-hearts"></i>
                                    </div><FormattedMessage {...LANG_COMMON_PATIENTS}/></CustomLink></li>                                    
                                    {hasAbility("BUSINESS_LIST") && (<li><CustomLink to={'/admin/businesses'}><div className="icon-aside">
                                        <i className="bi bi-bank2"></i>
                                    </div><FormattedMessage {...LANG_COMMON_CLINICS}/></CustomLink></li>)}
                                    <li><CustomLink to={'/admin/users'}><div className="icon-aside">
                                        <i className="bi bi-person-fill"></i>
                                    </div><FormattedMessage {...LANG_COMMON_USERS}/></CustomLink></li>
                                    {/*{hasAbility("SUBSCRIPTION_VIEW") && (<li><CustomLink to={'/admin/subscription'}><div className="icon-aside">
                                        <i className="bi bi-credit-card"></i>
                                    </div><FormattedMessage {...LANG_COMMON_SUBSCRIPTION}/></CustomLink></li>)}*/}
                                    <li><CustomLink
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowTerms(true);
                                        }}
                                        to={"/admin/terms"}><div className="icon-aside">
                                        <i className="bi bi-file-text-fill"></i>
                                    </div><FormattedMessage {...LANG_COMMON_USE_TERMS}/></CustomLink></li>
                                    <li><CustomLink target={"_blank"} to={`/assets/orientacoes.pdf`}><div className="icon-aside">
                                        <i className="bi bi-info-circle-fill"></i>
                                    </div><FormattedMessage {...LANG_COMMON_INSTRUCTIONS}/></CustomLink></li>

                                </ul>
                            </div>
                            {children}
                        </div>
                    </>
                )}
            </AuthContext.Consumer>
        </>
    );
}
export default Layout