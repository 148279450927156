import React, { FC, useState } from "react";
import { Form, Modal, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import { PersonType } from "../../../../types/PersonType";
import { PersonModel } from "../../../../models/PersonModel";
import { isResponseError, ResponseErrorType } from "../../../../types/CommonType";
import TranslateTerms from "../../../../components/TranslateTerms";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_CANCEL, LANG_COMMON_LEGAL_ID, LANG_COMMON_LOADING, LANG_COMMON_MEDICAL_RECORD_NUMBER, LANG_COMMON_NAME, LANG_COMMON_NEW, LANG_COMMON_PATIENT, LANG_COMMON_SAVE, LANG_ERROR, LANG_WARNING_FILL_ALL } from "../../../../lang";
import InputMask from 'react-input-mask';
import clsx from "clsx";

interface Props {
    show: boolean
    onHide: () => void
    onSave: (person: PersonType) => void
    type: string,
    couple_id: number
}

const PersonAddModal: FC<Props> = ({ show, onHide, onSave, type, couple_id }) => {
    const schema = yup.object().shape({
        name: yup.string().required(),
        cpf: yup.string().required()
    });
    const [formWasSubmitted, setFormWasSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const intl = useIntl();
    const DefaultErrorMsg = intl.formatMessage(LANG_ERROR);
    const [errorMsg, setErrorMsg] = useState(DefaultErrorMsg);

    const initialPerson: PersonType = {
        id: -1,
        name: "",        
        type: type,        
        cpf: "",
        rg: "",
        couple_id: couple_id
    };

    const onSubmit = async (values: PersonType) => {
        setLoading(true);
        setFormWasSubmitted(false);
        setErrorMsg(DefaultErrorMsg);
        setHasError(false);
        // Create person
        let resp: PersonType | ResponseErrorType = await PersonModel().create({ ...values, type: type });
        setLoading(false);
        // Saving result
        if (!isResponseError(resp)) {
            onSave(resp as PersonType);
        } else {
            setHasError(true);
            setErrorMsg((resp as ResponseErrorType).message);
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                setFormWasSubmitted(false);
            }}
            className="modal-default"
        >
            <Formik
                validationSchema={schema}
                onSubmit={onSubmit}
                initialValues={initialPerson}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isValid,
                    errors }) => (
                    <Form noValidate onSubmit={(e) => {
                        setFormWasSubmitted(true);
                        handleSubmit(e);
                    }}>
                        <Modal.Header closeButton closeVariant="white">
                            <Modal.Title>
                            <FormattedMessage {...LANG_COMMON_NEW}/> <FormattedMessage {...LANG_COMMON_PATIENT}/>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(!isValid && formWasSubmitted) && (<Alert variant={'danger'}><FormattedMessage {...LANG_WARNING_FILL_ALL} /></Alert>)}
                            {hasError && (<Alert variant={'danger'}>{errorMsg}</Alert>)}
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label><FormattedMessage {...LANG_COMMON_NAME} /></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder={intl.formatMessage(LANG_COMMON_NAME)}
                                    value={values.name}
                                    onChange={handleChange}
                                    isValid={touched.name && !errors.name}
                                    isInvalid={!!errors.name && formWasSubmitted}
                                />
                            </Form.Group>                            
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label><FormattedMessage {...LANG_COMMON_LEGAL_ID} /></Form.Label>
                                <InputMask 
                                    placeholder={intl.formatMessage(LANG_COMMON_LEGAL_ID)}
                                    value={values.cpf}
                                    onChange={handleChange}
                                    name={"cpf"}
                                    type="text"
                                    mask="999.999.999-99"                            
                                    className={clsx("form-control", {
                                        "is-invalid": touched.cpf && errors.cpf,
                                        "is-valid": !errors.cpf && touched.cpf
                                    })}
                                />                                
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                                <FormattedMessage {...LANG_COMMON_CANCEL} />
                            </Button>
                            <Button variant="primary" type={"submit"} disabled={loading} className="btn-custom bg-custom-gradient">
                                {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> <FormattedMessage {...LANG_COMMON_LOADING} /></> : <FormattedMessage {...LANG_COMMON_SAVE} />}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default PersonAddModal;