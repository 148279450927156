import React, {FC, useEffect, useReducer} from "react";
import { MedicalRecordContextType, MedicalRecordType } from "../types/MedicalRecordType";
import { MedicalRecordModel } from "../models/MedicalRecordModel";
import { MedicalRecordReducer } from "../reducers/MedicalRecordReducer";
import { TreatmentContextType, TreatmentResultType, TreatmentType } from "../types/TreatmentType";
import { TreatmentReducer } from "../reducers/TreatmentReducer";
import { TreatmentModel } from "../models/TreatmentModel";

interface Props {
    treatment: TreatmentType | null
}

const DefaultData: TreatmentContextType = {
    treatment: undefined,
    setTreatment: (treatment: TreatmentType) => {},
    loading: false,
    loadingResults: false,
    reload: () => {},
    loadResults: (medical_record_id: number, treatment_id: number) => {},
    setResults: (results: TreatmentResultType) => {},
    results: []
};
const TreatmentContext = React.createContext<TreatmentContextType>(DefaultData);
const TreatmentContextProvider: FC <Props> = ({children, treatment}) => {    

    const setTreatment = (treatment: TreatmentType) => {
        dispatch({type: 'setTreatment', treatment: treatment});
        loadResults(treatment.medical_record_id, treatment.id);
    }

    const setResults = (results: TreatmentResultType[]) => {
        dispatch({type: 'setResults', results: results});
    }

    const loadTreatment = (medical_record_id: number, treatment_id: number) => {
        dispatch({type: 'setLoading', loading: true});
        TreatmentModel().get(medical_record_id, treatment_id).then((treatment) => {
            dispatch({type: 'setLoading', loading: false});
            if(typeof treatment !== "boolean") {
                setTreatment(treatment);
            }
        });
    }

    const loadResults = (medical_record_id: number, treatment_id: number) => {
        dispatch({type: 'setLoadingResults', loadingResults: true});
        TreatmentModel().getResults(medical_record_id, treatment_id).then((results) => {
            dispatch({type: 'setLoadingResults', loadingResults: false});
            if(typeof results !== "boolean") {
                setResults(results);
            }
        });
    }

    const reload = () => {
        if(treatment)
            loadTreatment(treatment.medical_record_id, treatment.id);
    }    

    useEffect(() => {
        if(treatment)
            setTreatment(treatment);
    }, []);

    const [data, dispatch] = useReducer(TreatmentReducer, {
        ...DefaultData,
        setTreatment: setTreatment,
        reload: reload,
        loadResults: loadResults
    });
    return (<TreatmentContext.Provider value={data}>{children}</TreatmentContext.Provider>);
}

export {TreatmentContextProvider, TreatmentContext}