import moment from "moment";
import { OptionType } from "../types/CommonType";

export function useUtil() {
    const calculateAge = (birth_date: string, format: string) => {
        const moment_birth_date = moment(birth_date, format);
        if (moment_birth_date.isValid()) {
            return moment().diff(moment_birth_date, 'years');
        }
        return 0;
    }

    const getOption = (needle: string | undefined | null, options: Array<OptionType> | undefined) : OptionType | undefined => {
        if(needle === undefined || needle === null || options === undefined) return undefined;

        for(let i in options) {
            if(options[i].value === needle.toString())
                return options[i];
        }

        return undefined;
    }

    return {
        calculateAge,
        getOption
    };
}