import React, { FC, useContext, useEffect, useState } from "react";
import { Accordion, Badge, Button, Card, Col, Form, Image, Row, Table } from "react-bootstrap";
import { ImageUploadProvider, ImageUploadProviderContext } from "../../../providers/ImageUploadProvider";
import ImageUploader from "../../../components/ImageUploader";
import clsx from "clsx";
import { useAuth } from "../../../hooks/useAuth";
import moment from "moment";
import { useIntl } from "react-intl";
import { LANG_COMMON_CHOOSE, LANG_COMMON_DELETE_CONFIRM, LANG_COMMON_DELETE_ERROR, LANG_COMMON_DELETE_SUCCESS, LANG_COMMON_LOADING, LANG_COMMON_VIEW, LANG_PERSON_AGE, LANG_PERSON_EYES_COLOR, LANG_PERSON_HAIR_COLOR, LANG_PERSON_HAIR_TYPE, LANG_PERSON_HEIGHT, LANG_PERSON_RACE, LANG_PERSON_SKIN_COLOR } from "../../../lang";
import { OptionsContext } from "../../../providers/OptionsContextProvider";
import { PersonType } from "../../../types/PersonType";
import { useUtil } from "../../../hooks/useUtil";
import "../treatment-table.css";
import { MedicalRecordEvolutionType, MedicalRecordType } from "../../../types/MedicalRecordType";
import { MedicalRecordModel } from "../../../models/MedicalRecordModel";
import { PersonModel } from "../../../models/PersonModel";
import { TreatmentResultType, TreatmentType } from "../../../types/TreatmentType";
import CustomSelect from "../../../components/form/CustomSelect";
import TreatmentAddModal from "./modal/TreatmentAddModal";
import TreatmentResultAddModal from "./modal/TreatmentResultAddModal";
import { TreatmentModel } from "../../../models/TreatmentModel";
import { OptionType, isResponseError } from "../../../types/CommonType";
import { TreatmentContext, TreatmentContextProvider } from "../../../providers/TreatmentContextProvider";
import Loader from "../../../components/messages/Loader";
import LoaderInside from "../../../components/messages/LoaderInside";
import Confirm from "../../../components/messages/Confirm";
import { toast } from "react-toastify";
import { ExamContext, ExamContextProvider } from "../../../providers/ExamContextProvider";
import ExamModal from "./modal/ExamModal";
import { ExamModel } from "../../../models/ExamModel";
import { ExamType } from "../../../types/ExamType";
import Error from "../../../components/messages/Error";
import ExamFiles from "./ExamFiles";

interface Props {
    medical_record: MedicalRecordType
}

const MedicalRecordExamTab: FC<Props> = ({ medical_record }) => {
    const [showModal, setShowModal] = useState(false);
    const [examToEdit, setExamToEdit] = useState<ExamType | undefined>(undefined);
    const [errorMsg, setErrorMsg] = useState<string | undefined>("");
    const [loadingExam, setLoadingExam] = useState(false);

    const createExam = async () => {
        setLoadingExam(true);
        const resp = await ExamModel().create({
            id: -1,
            type: "",
            lab_name: "",
            date: moment().format("YYYY-MM-DD"),
            results: [],
            files: [],
            medical_record_id: medical_record.id
        }, medical_record.id);

        setLoadingExam(false);
        if (!isResponseError(resp)) {
            setExamToEdit(resp as ExamType);
        } else {
            setErrorMsg("Houve um erro desconhecido, tente novamente!");
        }
    }

    const calculateElapsedDays = (date: string) => {
        const today = moment();
        const pastDate = moment(date);

        return today.diff(pastDate) / (1000 * 60 * 60 * 24);
    }

    const isExpired = (date: string, type: string) => {
        return calculateElapsedDays(date) >= 90 && type === 'SORO';
    }

    return (
        <ExamContextProvider medical_record_id={medical_record.id}>
            <ExamContext.Consumer>
                {({ loading, exam_options, exams, loadExams }) => (
                    <>
                        {loadingExam && (
                            <Loader
                                fullscreen={true}
                            />
                        )}
                        {examToEdit && (
                            <ExamModal
                                show={true}
                                onHide={() => {
                                    setExamToEdit(undefined);
                                }}
                                onSave={(treatment) => {
                                    setExamToEdit(undefined);
                                    loadExams(medical_record.id);
                                }}
                                medical_record={medical_record}
                                exam_options={exam_options}
                                exam={examToEdit}
                                key={examToEdit.id}
                            />
                        )}
                        {errorMsg && (
                            <Error
                                message={errorMsg}
                                show={true}
                                onHide={() => setErrorMsg(undefined)}
                            />
                        )}
                        <Row>
                            <Col lg={{ span: 3, offset: 9 }}>
                                <Button
                                    className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100 btn btn-primary mb-2"
                                    onClick={createExam}
                                >Inserir resultado</Button>
                            </Col>
                            <Col lg={12}>
                                <hr />
                            </Col>
                            <Col lg={12}>
                                {loading && (
                                    <LoaderInside />
                                )}
                                {!loading && (
                                    <Accordion>
                                        {ExamModel().getExamTypes().map((examType, indexType) => (
                                            <Accordion.Item eventKey={`type-${indexType}`}>
                                                <Accordion.Header>{examType.label}</Accordion.Header>
                                                <Accordion.Body>

                                                    <Accordion className="accordion-inside">
                                                        {exams.map((exam, indexExam) => {
                                                            if (exam.type === examType.value) {
                                                                return (
                                                                    <Accordion.Item eventKey={`exam-${indexExam}`}>
                                                                        <Accordion.Header className={clsx("", {
                                                                            'expired-exam': isExpired(exam.date, exam.type)
                                                                        })}>{moment(exam.date).format("DD/MM/YYYY")}{isExpired(exam.date, exam.type) ? ' - VENCIDO' : ''}</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <a href="" className="btn btn-light" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                setExamToEdit(exam);
                                                                            }}>
                                                                                <i className="bi bi-pencil-fill"></i>
                                                                            </a>
                                                                            <Row>
                                                                                {exam_options.map((examOption, indexOption) => {
                                                                                    if (examOption.type === examType.value && examOption.format !== 'LONGTEXT') {
                                                                                        return (
                                                                                            <Col lg={3} className="exam-list">
                                                                                                <Form.Group className="mb-3">
                                                                                                    <Form.Label>{examOption.label}</Form.Label>
                                                                                                    <div>{ExamModel().getResult(examOption.code, exam.results)?.value}</div>
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                        );
                                                                                    }
                                                                                })}
                                                                                {exam_options.map((examOption, indexOption) => {
                                                                                    if (examOption.type === examType.value && examOption.format === 'LONGTEXT') {
                                                                                        return (
                                                                                            <Col lg={12} className="exam-list">
                                                                                                <Form.Group className="mb-3">
                                                                                                    <Form.Label>{examOption.label}</Form.Label>
                                                                                                    <div dangerouslySetInnerHTML={{__html: ExamModel().getResult(examOption.code, exam.results)?.value || ""}} />
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                        );
                                                                                    }
                                                                                })}
                                                                            </Row>
                                                                            <ExamFiles
                                                                                files={exam.files}
                                                                                exam={exam}
                                                                                onSave={() => loadExams(exam.medical_record_id)}
                                                                            />
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                )
                                                            }
                                                        })}
                                                    </Accordion>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </ExamContext.Consumer>
        </ExamContextProvider>

    );
}

export default MedicalRecordExamTab;